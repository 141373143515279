// viewComponent.js and navbarComponent.js
body {
  background-color: $background;
}

.applystart-view {
  min-height: 100vh;
  padding-bottom: 25vh;
}

.applystart-view-footer {
  height: 20vh;
  background: url("../graphics/site-art/roadway.svg");
  background-size: cover; /* Adjust the background size as needed */
  background-position: center;
}

.navbar, {
  //height: 8rem;
  box-shadow: 0px -3px 3px -3px darkgray inset;

}

.dynamic-title-nav {
  box-shadow: 4px 4px 2px -2px $primary-lighter;
  z-index: 100;
}

.navbar-bg {
  background-image: url("../../src/graphics/site-art/page-header.png");
  background-size: cover;

}

.nav-notification-count {
  border-radius: 30px;
}

.nav {


  &-route {
    color: $primary;

    i {
      -webkit-text-stroke: $primary 0.5px;
      //color: $primary;
    }

    &.selected {
      border-bottom: 3px $secondary solid;
      color: $secondary !important;

      i {
        -webkit-text-stroke: $secondary 0.5px;
      }
    }

    &:hover {
      color: $secondary !important;
      transform: scale(1.1);

      i {
        -webkit-text-stroke: $secondary 0.5px;
      }
    }
  }

  &-item {
    &:hover {
      transform: scale(1.05);
    }

    &.selected {
      border-bottom: 4px solid $secondary-lighter;

      .nav-link {
        color: $secondary-lighter !important;
      }
    }
  }

  &-user {
    & .dropdown-menu {
      left: -120px !important;
    }

    &-btn:hover + .nav-user-names {
      text-decoration: 1px solid $light underline !important;

    }
  }

}

.show .nav-route {
  color: $secondary !important;
  transform: scale(1.05);
}

mark {
  background: $gray-300;
  font-weight: bold;
}

.institution-card {
  &:hover {
    & .institution-card-image {
      transform: scale(1.1);
      transition: 0.3s;
    }
  }

  &-image {
    height: 20vh;
    background-size: cover;
  }
}


.hover-card-scale:hover {
  transform: scale(1.03);
  color: $secondary;
  transition: 0.05s;

  img {
    transform: scale(1.05);
  }
}

.dfc-field {
  min-height: 35px !important;
  place-content: end !important;
}

.dfc-value.dfc-field {
  //width: fit-content;
  //border-width: 0px 0px 0px 0px;
  border-radius: calc($border-radius)-3px;
  padding: 0.2rem;
  background: $disabled-data;
  color: #888;
  //box-shadow: -2px 2px 1px 2px var(--dark);
  //width: 100%;
  //display: inline-block;
  &:hover {
    //background: $disabled-data;

  }
}

.institution-jumbotron-image-overlay {
  height: auto;
  background-size: cover;
  background-repeat: no-repeat;
}

.institution-jumbotron {
  height: min-content;
}


.h-80px {
  height: 80px;
}

/* this is needed to make the content scrollable on larger screens */


@media (max-width: 991px) {
  .page-component.nav-enabled {
    margin-top: 7rem;
  }

  .top-navsize {
    top: 160px;
  }

  .text-xs-small{
    font-size: small !important;
  }

}

@media (min-width: 992px) {
  .page-component.nav-enabled {
    margin-top: 7rem;

  }
  .top-navsize {
    top: 119px;
  }

}

.page-component {
  //min-height: 71vh;
}

.page-component.nav-enabled {
  //min-height: 55vh;
}

h5 {
  margin: 0;
}

