@import 'applystart_theme.scss';

@import 'bootstrap/scss/bootstrap';
@import 'bootstrap_override';

@import 'applystart_styles.scss';
@import 'applystart_common.scss';

@import 'bootstrap-icons/font/bootstrap-icons';
@import 'tippy.js/dist/tippy.css';
@import 'flag-icons/sass/flag-icons'; //flags of country




