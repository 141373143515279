@import "mixins";
/* this is needed to make the content scrollable on larger screens */
@media (min-width: 576px) {
    .h-sm-100 {
        height: 100%;
    }
}

.z-index-1{
    z-index: 1 !important;
}


a > .btn{
    color: $light !important;
}

.row{
    //margin-top: 1rem;
}


.accordion-collapse {
    &.show {
       animation: slide-down 0.3s ease 1 normal;
    }
}

.text-gray{
    //color: grey !important;
}

@keyframes slide-down{
    from {margin-top: -80px;} to {margin-top: 0px;}
}

#country-select-input {
    height: 0;
}



.place-content-center{
    place-content: center;
}

.btn{
    //padding: 0.60rem;
    border-width: 2px;
    -webkit-text-stroke-width: 0.35px;
    border-radius: calc($border-radius)-5px;
}

.dropdown-toggle-no-arrow::after{
    content: unset !important;
}
.dropdown-toggle::after{
    content: unset;
}

.btn .badge{
    top: unset;
}

.card{
    box-shadow: 0 2px 4px rgba(0, 0, 0, .1), 0 8px 16px rgba(0, 0, 0, .1);
}

.pac-container{
    z-index: 10000;
}


//sizes
/*
*
* Sizes
*
* Static width and height utils.
*
*/

$sizes: (
        '0': 0,
        '1': 0.5rem,
        '2': 1rem,
        '3': 1.5rem,
        '4': 2rem,
        '5': 2.5rem,
        '6': 3rem,
        '7': 3.5rem,
        '8': 4rem,
        '9': 4.5rem,
        '10': 5rem,
        '11': 5.5rem,
        '12': 6rem,
        '13': 6.5rem,
        '14': 7rem,
        '15': 7.5rem,
        '16': 8rem,
        '17': 8.5rem,
        '18': 9rem,
        '19': 9.5rem,
        '20': 10rem,
        '21': 10.5rem,
        '22': 11rem,
        '23': 11.5rem,
        '24': 12rem,
        '25': 12.5rem,
        '30': 15rem,
        '35': 17.5rem,
        '40': 20rem,
        '45': 22.5rem,
        '50': 25rem,
        '60': 30rem,
        '70': 35rem,
        '80': 40rem,
);

$sizesPercent: 25, 35,  50, 75, 100;

.h-100-card {
    height: calc(100% - var(--small-title-height)) !important;
}

.w-100 {
    width: 100% !important;
}

.w-90 {
    width: 90% !important;
}

.w-80 {
    width: 80% !important;
}

.w-75 {
    width: 75% !important;
}

.w-70 {
    width: 70% !important;
}

.w-60 {
    width: 60% !important;
}

.w-50 {
    width: 50% !important;
}

.w-40 {
    width: 40% !important;
}

.w-33 {
    width: 33.3% !important;
}

.w-30 {
    width: 30% !important;
}

.w-25 {
    width: 25% !important;
}

.w-20 {
    width: 20% !important;
}

.w-10 {
    width: 10% !important;
}

.h-auto {
    height: auto !important;
}

.w-auto {
    width: auto !important;
}

@each $name, $size in $sizes {
    .sh-#{$name} {
        height: $size !important;
    }
    .sw-#{$name} {
        width: $size !important;
    }
}

@each $name, $size in $sizes {
    .sh-sm-#{$name} {
        @include respond-above(xs) {
            height: $size !important;
        }
    }

    .sw-sm-#{$name} {
        @include respond-above(xs) {
            width: $size !important;
        }
    }
}

@each $name, $size in $sizes {
    .sh-md-#{$name} {
        @include respond-above(sm) {
            height: $size !important;
        }
    }

    .sw-md-#{$name} {
        @include respond-above(sm) {
            width: $size !important;
        }
    }
}

@each $name, $size in $sizes {
    .sh-lg-#{$name} {
        @include respond-above(md) {
            height: $size !important;
        }
    }

    .sw-lg-#{$name} {
        @include respond-above(md) {
            width: $size !important;
        }
    }
}

@each $name, $size in $sizes {
    .sh-xl-#{$name} {
        @include respond-above(lg) {
            height: $size !important;
        }
    }

    .sw-xl-#{$name} {
        @include respond-above(lg) {
            width: $size !important;
        }
    }
}

@each $name, $size in $sizes {
    .sh-xxl-#{$name} {
        @include respond-above(xl) {
            height: $size !important;
        }
    }

    .sw-xxl-#{$name} {
        @include respond-above(xl) {
            width: $size !important;
        }
    }
}

.h-sm-100-card {
    @include respond-above(xs) {
        height: calc(100% - var(--small-title-height)) !important;
    }
}

.h-md-100-card {
    @include respond-above(sm) {
        height: calc(100% - var(--small-title-height)) !important;
    }
}

.h-lg-100-card {
    @include respond-above(md) {
        height: calc(100% - var(--small-title-height)) !important;
    }
}

.h-xl-100-card {
    @include respond-above(lg) {
        height: calc(100% - var(--small-title-height)) !important;
    }
}

.h-xxl-100-card {
    @include respond-above(xl) {
        height: calc(100% - var(--small-title-height)) !important;
    }
}

.h-sm-100 {
    @include respond-above(xs) {
        height: 100% !important;
    }
}

.h-md-100 {
    @include respond-above(sm) {
        height: 100% !important;
    }
}

.h-lg-100 {
    @include respond-above(md) {
        height: 100% !important;
    }
}

.h-xl-100 {
    @include respond-above(lg) {
        height: 100% !important;
    }
}

.h-xxl-100 {
    @include respond-above(xl) {
        height: 100% !important;
    }
}

.h-sm-auto {
    @include respond-above(xs) {
        height: auto !important;
    }
}

.h-md-auto {
    @include respond-above(sm) {
        height: auto !important;
    }
}

.h-lg-auto {
    @include respond-above(md) {
        height: auto !important;
    }
}

.h-xl-auto {
    @include respond-above(lg) {
        height: auto !important;
    }
}

.h-xxl-auto {
    @include respond-above(xl) {
        height: auto !important;
    }
}

.w-sm-auto {
    @include respond-above(xs) {
        width: auto !important;
    }
}

.w-md-auto {
    @include respond-above(sm) {
        width: auto !important;
    }
}

.w-lg-auto {
    @include respond-above(md) {
        width: auto !important;
    }
}

.w-xl-auto {
    @include respond-above(lg) {
        width: auto !important;
    }
}

.w-xxl-auto {
    @include respond-above(xl) {
        width: auto !important;
    }
}

@each $size in $sizesPercent {
    .h-sm-#{$size} {
        @include respond-above(xs) {
            height: unquote($size + '%') !important;
        }
    }

    .w-sm-#{$size} {
        @include respond-above(xs) {
            width: unquote($size + '%') !important;
        }
    }
}

@each $size in $sizesPercent {
    .h-md-#{$size} {
        @include respond-above(sm) {
            height: unquote($size + '%') !important;
        }
    }

    .w-md-#{$size} {
        @include respond-above(sm) {
            width: unquote($size + '%') !important;
        }
    }
}

@each $size in $sizesPercent {
    .h-lg-#{$size} {
        @include respond-above(md) {
            height: unquote($size + '%') !important;
        }
    }

    .w-lg-#{$size} {
        @include respond-above(md) {
            width: unquote($size + '%') !important;
        }
    }
}

@each $size in $sizesPercent {
    .h-xl-#{$size} {
        @include respond-above(lg) {
            height: unquote($size + '%') !important;
        }
    }

    .w-xl-#{$size} {
        @include respond-above(lg) {
            width: unquote($size + '%') !important;
        }
    }
}

@each $size in $sizesPercent {
    .h-xxl-#{$size} {
        @include respond-above(xl) {
            height: unquote($size + '%') !important;
        }
    }

    .w-xxl-#{$size} {
        @include respond-above(xl) {
            width: unquote($size + '%') !important;
        }
    }
}

.min-h-100 {
    min-height: 100%;
}

.max-h-100 {
    max-height: 100%;
}

.min-w-100 {
    min-width: 100%;
}

.max-w-100 {
    max-width: 100%;
}

.min-w-0 {
    min-width: 0;
}

.min-h-0 {
    min-height: 0;
}

a.text-decoration-none :hover {
    text-decoration: none !important;

}
