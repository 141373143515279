
.bg {
  &-lightgray {
    background: $light-gray;
  }

  &-dark {
    &-10 {
      background: rgba(0, 0, 0, 0.1);
    }

    &-70 {
      background: rgba(0, 0, 0, 0.7);
    }

  }

  &-primary {
    .close {
      color: $light !important;
    }
  }

  &-primary-light {
    background: $primary-lighter;

    .text-muted {
      color: darkgray !important;
    }
  }
}

hr {
  width: 75% !important;
  margin-left: auto !important;
  margin-right: auto !important;


  /* Flaired edges, by Tomas Theunissen */

  &.style-edge {
    overflow: visible; /* For IE */
    height: 30px;
    border-style: solid;
    border-color: black;
    border-width: 1px 0 0 0;
    border-radius: 20px;
  }

  &.style-edge:before { /* Not really supposed to work, but does */
    display: block;
    content: "";
    height: 30px;
    margin-top: -31px;
    border-style: solid;
    border-color: black;
    border-width: 0 0 1px 0;
    border-radius: 20px;
  }
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.nav-row {
  text-align: center;
  color: $light;

}

.max-vh-50 {
  max-height: 50vh;
}

.min-h-200px {
  min-height: 200px;
}


.btn {
  &-primary {
    &:hover {
      background-color: $primary-darker !important;
      color: $light !important;
    }
  }

  &-secondary {
    color: $light
  }

  &-outline-tertiary{
    border: 1px solid  $tertiary;
    color: $tertiary !important;

    &:hover{
     background-color: $tertiary;
      color: $light !important;
    }
  }

  &-tertiary {
    background-color: $tertiary;
    color: $light;

    &:hover, &:focus {
      background-color: dimgray;
      color: $light;
      border: 1px solid $primary;
    }
  }

  &-group * {
    margin: 0.5rem;
  }
}

@media (min-width: 992px) {
  .text-md-large{
    font-size: large !important;
  }

  .text-md-x-large{
    font-size: x-large !important;
  }
}

.text {

  &-large {
    font-size: x-large;
  }

  &-x-large {
    font-size: xx-large;
  }

  &-extra-small {
    font-size: 0.75em !important;
    line-height: 1em !important;
  }

  &-medium {
    font-size: large !important;
  }

  &-subtitle{
    font-size: 0.9rem;
  }

  &-small {
    font-size: small;
  }

  &-left {
    text-align: left !important;
  }

  &-shadow {
    &-dark {
      text-shadow: 2px 2px $dark;
    }
  }

  &-tertiary {
    color: $tertiary;
  }
}


.hover {
  &-scale:hover {
    transform: scale(1.05);
  }

  &-text-underline:hover {
    text-decoration: 1px solid $light underline !important;
  }
}

.overflow-y-scroll {
  overflow-y: auto;
}



.hover {
  &-bg-secondary:hover {
    background-color: $secondary;
  }

  &-bg-gray:hover {
    background-color: $light-gray !important;
  }

  &-text-light:hover {
    color: $light;
  }
}

.cursor {
  &-pointer {
    cursor: pointer;
  }
}


//animating class
.fade-in-and-out {
  opacity: 1;
  animation: fade-in-and-out 1.5s linear infinite;
}


@keyframes fade-in-and-out {
  0% {
    opacity: 0.2;
    transform: scale(0.5);
  }
  50% {
    opacity: 1;
    transform: scale(1.1);
  }
  100% {
    opacity: 0.2;
    transform: scale(1);
  }
}

.shadow-very-deep {
  box-shadow: 0 2px 10px rgb(0 0 0 / 0.2);
}

.bottom-0 {
  bottom: 0;
}

.object-fit-cover {
  object-fit: cover;
}

.bg-separator {
  background-color: $gray-400 !important;
  color: $light !important;
}

.line-w-1 {
  width: 1px;
}


.btn-circle {
  width: 45px;
  height: 45px;
  border-radius: 35px;
  font-size: medium;
  line-height: 1.5;
  margin-top: 0.4rem !important;
}

.text-underline {
  text-decoration: 2px underline;
}

.vertical-align-bottom {
  vertical-align: bottom;
}

.vh-80 {
  height: 80vh;
}

.bg-cover {
  background-size: cover;

}

.l-1 {
  left: 1rem;
}

.p-45rem {
  padding: 0.45rem;
}

[class^="col"] {
  margin-bottom: 0.25rem !important;
}

.filter-brightness-50 {
  filter: brightness(50%);
}

.hover-text-secondary:hover{
  color: $secondary;
}

.left-25 {
  left: 25%;
}

.top-0 {
  top: 0
}

.z-index-1000{
  z-index: 1000;
}

.hover-border:hover{
 border: 1px solid $secondary
}

label{
  font-weight: bolder;
}

.text-shadow{
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); /* Add a subtle black shadow with a blur effect */
}

.text-decoration-underline{
  text-decoration: underline !important;
}

.border-gray{
  border-color: grey;
}
