$primary: #282A38;
$primary-lighter: linear-gradient(to left top, #282a38, #26283b, #25273d, #242540, #232342);
$primary-darker: #010B2E; 
$secondary: #DC3545;
$secondary-lighter: #FF5A60;
$secondary-disabled: maroon;
//$danger: #D44C31;
$success: #01b9a5;
$warning: #E3C032;
$tertiary: #808080;
$disabled-data: #f2f2f2;
$light-gray: #F0F2F5;
$background: #F0F2F5;
$light: #F7F7F7;

$border-radius: 12px;
$link-color: #3c3151;





body{
  font-family: 'Ubuntu', sans-serif !important;


  h2{
    font-weight: lighter;
  }
  h6{
    margin: 0;
    //font-weight: bold;

  }
}
